import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Events from './pages/Events';
import Reports from './pages/Reports';
import Projects from './pages/Projects';
import ViewProjects from './pages/ViewProjects';
import VideoPage from './pages/VideoPage';
import VideoSafety from './pages/VideoSafety';
import VideoEV from './pages/VideoEV';
import VideoProgram from './pages/VideoProgram';
import VideoJCB from './pages/VideoJCB';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/viewprojects/:id" element={<ViewProjects />} />
          <Route path='/video' element={<VideoPage/>} />
          <Route path='/video2' element={<VideoSafety/>}/>
          <Route path='/videoev' element={<VideoEV/>}/>
          <Route path='/videoprogram' element={<VideoProgram/>}/>
          <Route path = '/videojcb' element ={<VideoJCB/>}/>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
