import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFolderOpen, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'
import { faCalendar as farCalendar, faFolderOpen as farFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { Skeleton } from 'primereact/skeleton';
import img from '../assets/Johnson Courthouse Photo.jpg'
import logo from '../assets/Johnson County Transportation Bond logo transparent.png'
import video from '../assets/Johnson County Bond Announcement Video.MOV'

export default function Home() {

    const calendaricn = <FontAwesomeIcon icon={farCalendar} fontSize={"85px"} color='#124c7c' />
    const foldericn = <FontAwesomeIcon icon={farFolderOpen} fontSize={"85px"} color='#124c7c' />
    const charticn = <FontAwesomeIcon icon={faPersonChalkboard} fontSize={"85px"} color='#124c7c' />

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>

                <img className="d-block w-100" src={img} style={{ objectFit: "cover", height: "20em" }} alt="Home" />

                <div className="container py-4">
                    <div className='row mx-5 mt-4'>
                        <div className='col-sm-3 col-12 text-center'>
                            <img src={logo} height={"300px"} alt="Johnson County Transportation Program" />
                            <div className='pt-3'>
                                <a href="https://www.x.com/@JohnsonCoBond" target='_blank' style={{ textDecoration: "none" }}><h5 className='pb-2'><i className="fa-brands fa-square-x-twitter"></i> @JohnsonCoBond</h5></a>
                                <a href="https://www.instagram.com/JohnsonCountyBond" target='_blank' style={{ textDecoration: "none" }}><h5 className='pb-2'><i className="fa-brands fa-instagram"></i> @JohnsonCountyBond</h5></a>
                                <a href="https://www.facebook.com/people/Johnson-County-Transportation-Bond/61566498354803/" target='_blank' style={{ textDecoration: "none" }}><h5><i className="fa-brands fa-square-facebook"></i> Johnson County Transportation Bond</h5></a>
                            </div>
                        </div>
                        <div className="col-sm-9 col-12">
                            <h1 className='mb-3'>Johnson County Bond Program</h1>
                            {/* 
                            <video controls="controls" name="Johnson County" id="videoDiv" style={{ width: "100%" }}>
                                <source src={video} />
                            </video>
                            */}
                            <h5>
                                Voters approved the county's first transportation bond program on November 5, 2024!
                            </h5>
                            <p>
                                With the significant population growth and many residents relying on the roadway system for their daily commute, there was an increased demand on the infrastructure.  This $60 million bond program aims to enhance safety and mobility for residents.

                            </p>
                            <p>
                                The bond program will also support ongoing efforts by the Texas Department of Transportation (TxDOT) and the North Central Texas Council of Governments (NCTCOG). It will focus on project planning and engineering, with the goal of partnering with TxDOT and NCTCOG to secure additional state and federal funds for construction.
                            </p>
                            <p>
                                Please visit the  <Link to='/projects' style={{ color: "#124c7c" }}>Projects</Link> tab  for more information.
                            </p>

                        </div>
                    </div>

                    <div>
                        <div className='row p-4 text-center'>
                            {/*
                            <div className='col-lg-4'>
                                <Link to='/events' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {calendaricn}
                                    <h5 className='my-2'>Events</h5>
                                </Link>
                            </div>
                            */}
                            <div className='col-lg-4'>
                                <Link to='/projects' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {foldericn}
                                    <h5 className='my-2'>Projects</h5>
                                </Link>
                            </div>
                            {/*
                            <div className='col-lg-4'>
                                <Link to='/presentations' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {charticn}
                                    <h5 className='my-2'>Reports</h5>
                                </Link>
                            </div>
                            */}
                        </div>

                    </div>
                    <div className='col-lg-4'>

                    </div>
                </div>

            </main >
        </div >
    )
}