export default function Footer() {
    return (
        <div style={{ backgroundColor: "#0B3250" }}>
            <footer className="container py-4 mt-5">
                <div className='row text-light'>
                    <div className='col-lg-9 col-xs-9 text-start'>
                        <p>© Copyright {new Date().getFullYear()}</p>
                    </div>
                    <div className="col-lg-3 col-xs-3 text-end fa-2x">
                        <a href="https://www.x.com/@JohnsonCoBond" style={{ textDecoration: "none" }} target='_blank' className="text-light pe-3"><i className="fa-brands fa-square-x-twitter"></i></a>
                        <a href="https://www.instagram.com/JohnsonCountyBond" style={{ textDecoration: "none" }} target='_blank' className="text-light pe-3"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://www.facebook.com/people/Johnson-County-Transportation-Bond/61566498354803/" target='_blank' style={{ textDecoration: "none" }} className="text-light"><i className="fa-brands fa-square-facebook"></i></a>
                    </div>
                </div>
            </footer>
        </div>
    )
}