import { React, useState, useEffect } from 'react'
import EventsList from "../components/Events.json";
import Event from '../components/Event'
//import img from '../assets/Banners/Banner - 004.jpg'
import { Skeleton } from 'primereact/skeleton';
import img from '../assets/Johnson Courthouse Photo.jpg'

export default function Events() {
    const [upcomingEventsList, setUpcomingEventsList] = useState([]);
    const [pastEventsList, setPastEventsList] = useState([]);

    useEffect(() => {

        EventsList.sort((a, b) => {
            return new Date(a.Date) - new Date(b.Date);
        });
        var past = [], upcoming = [];

        var yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);

        EventsList.forEach(e => {
            (new Date(e.Date) > new Date(yesterdayDate)) ? upcoming.push(<Event event={e} key={e.id} />) : past.push(<Event event={e} key={e.id} />)
        });

        setUpcomingEventsList(upcoming);
        setPastEventsList(past);

    }, [])


    /*
    FOR EVENTS.JSON:
    {
        "Title": "",
        "Date": "2025-09-18",
        "Time": "",
        "Body": "",
        "location": "",
        "id": 1
    }

    */
    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>

                <img src={img} className="d-block w-100" style={{ objectFit: "cover", height: "20em" }} alt="Home" />

                <div className="container py-4">

                    <h1>Upcoming Events</h1>
                    {(upcomingEventsList.length === 0) ? <h3 className="text-center text-secondary py-2"><em>No Upcoming Events</em></h3> : upcomingEventsList}
                    <hr />
                    {/*
                    <h1>Past Events</h1>
                    {(pastEventsList.length === 0) ? <h3 className="text-center text-secondary"><em>No Past Events</em></h3> : pastEventsList}
                    */}
                </div>
            </main>
        </div>
    )
}